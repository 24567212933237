import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='search--clear-params'
export default class extends Controller {
  static targets = ['searchCount', 'item', 'groupItem'];

  clear(evt) {
    this.removeCategory()
    this.removeArea()
    this.uncheckedAll()
    this.updateCounter()
  }

  removeCategory() {
    const selecteds = document.getElementsByClassName('selectedSearchCategory')
    for (let selected of selecteds) {
      selected.innerHTML = ''
    }
  }

  removeArea() {
    const selecteds = document.getElementsByClassName('selectedSearchArea')
    for (let selected of selecteds) {
      selected.innerHTML = ''
    }
  }

  uncheckedAll() {
    this.itemTargets.forEach((el) => {
      if (el.name === 'keywords') {
        el.value = ''
      } else {
        el.checked = false
      }
    })

    this.groupItemTargets.forEach((el) => {
      if (el.type === 'checkbox') {
        el.checked = false
      } else {
        el.classList.add('u-visually-hidden')
      }
    })
  }

  updateCounter() {
    const headers = this.requestHeaders()

    fetch('/jobs/search/counter', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({})
      })
      .then(response => response.json())
      .then(responseData => {
        if (!responseData) {
          return;
        }
        const count = responseData.toLocaleString();
        if (this.hasSearchCountTarget) {
          this.searchCountTargets.forEach((el) => {
            el.innerHTML = count
          })
        }
      });
  }

  requestHeaders() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }

    return headers
  }
}