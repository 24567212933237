import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='checkbox-select-all'
export default class extends Controller {
  static targets = ['checkbox'];

  selectToggle(evt) {
    // 特定のターゲットの選択をトグルするロジックがここに入ります。
  }

  selectAllToggle(evt) {
    this.checkboxTargets.forEach((el) => {
      const isChecked = evt.target.checked;
      if (el.checked !== isChecked) {
        el.checked = isChecked;
      }
    })
  }
}
