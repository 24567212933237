import { Controller } from "@hotwired/stimulus"
import { arrayToHashObject } from '../../utils/app_utils';

// Connects to data-controller='search--select-modal-condition'
export default class extends Controller {
  static targets = ['searchCount', 'item', 'subItem', 'groupItem'];
  static classes = ['hide'];

  submit(evt) {
    this.settingCategory()
    this.settingArea()
  }

  settingCategory() {
    const selecteds = document.getElementsByClassName('selectedSearchCategory')
    for (let selected of selecteds) {
      selected.innerHTML = ''
      this.selectedCategory().forEach((category) => {
        selected.innerHTML += this.createItemHtml(category.id, category.value, 'categories_box')
      })
    }
  }

  settingArea() {
    const selecteds = document.getElementsByClassName('selectedSearchArea')
    for (let selected of selecteds) {
      selected.innerHTML = ''
      this.selectedArea().forEach((area) => {
        selected.innerHTML += this.createItemHtml(area.id, area.value, 'areas_box')
      })
      this.selectedMajorCity().forEach((majorCity) => {
        selected.innerHTML += this.createItemHtml(majorCity.id, majorCity.value, 'major_cities_box')
      })
    }
  }

  createItemHtml(id, value, name) {
    return `
      <li><label>
          <input type='checkbox'
                 value='${id}'
                 checked='checked'
                 name='${name}[]'
                 data-action='click->search--select-condition#selectToggle'
                 data-search--clear-params-target='item'
                 data-search--reset-params-target='item'
                 data-search--select-condition-target='item'
                 data-item-id='${id}'
                 data-item-value='${value}'>
          <span>${value}</span>
      </label></li>
    `
  }

  selectToggle(evt) {
    this.selectedGroupToggle(evt.target)
    this.updateCounter(evt)
  }

  selectedGroupToggle(target) {
    const itemGroupCode = target.dataset.groupCode

    var checked = false
    for (let itemTarget of this.itemTargets) {
      const groupCode = itemTarget.dataset.groupCode
      if (groupCode === itemGroupCode && itemTarget.checked === true) {
        checked = true
        break;
      }
    }

    for (let groupItem of this.groupItemTargets) {
      const groupCode = groupItem.dataset.groupCode
      if (groupCode === itemGroupCode) {
        if (checked) {
          groupItem.classList.remove(this.hideClass)
        } else {
          groupItem.classList.add(this.hideClass)
        }
        break;
      }
    }
  }

  selectedArea() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (isModal && el.name === 'areas_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedMajorCity() {
    const itemAreas = arrayToHashObject(this.selectedArea(), 'id')

    var array = []
    this.subItemTargets.forEach((el) => {
      const isModal = el.dataset.isModal
      const parentItemId = el.dataset.parentItemId

      // 選択済みAreaのデータをmap化
      const selectedAreaItem = itemAreas[parentItemId]

      if (!selectedAreaItem && isModal && el.name === 'major_cities_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedCategory() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (isModal && el.name === 'categories_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedField() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'fields_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedDiscerning() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'discernings_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedNewOnly() {
    var array = false
    this.itemTargets.forEach((el) => {
      if (el.name === 'new_only' && el.checked === true) {
        array = true
      }
    })

    return array
  }

  selectedCompanyOnly() {
    var array = false
    this.itemTargets.forEach((el) => {
      if (el.name === 'company_name_only' && el.checked === true) {
        array = true
      }
    })

    return array
  }

  selectedKeyword() {
    var keyword = ''
    this.itemTargets.forEach((el) => {
      if (el.name === 'keywords') {
        keyword = el.value.trim()
      }
    })

    return keyword
  }

  selectedAreaValues() {
    return this.selectedArea().map(item => item.value).join(',')
  }

  selectedMajorCityValues() {
    return this.selectedMajorCity().map(item => item.value).join(',')
  }

  selectedCategoryValues() {
    return this.selectedCategory().map(item => item.value).join(',')
  }

  selectedFieldValues() {
    return this.selectedField().map(item => item.value).join(',')
  }

  selectedDiscerningValues() {
    return this.selectedDiscerning().map(item => item.value).join(',')
  }

  updateCounter(evt) {
    const headers = this.requestHeaders()

    const areas = this.selectedAreaValues()
    const majorCities = this.selectedMajorCityValues()
    const categories = this.selectedCategoryValues()
    const fields = this.selectedFieldValues()
    const discernings = this.selectedDiscerningValues()
    const newOnly = this.selectedNewOnly()
    const companyOnly = this.selectedCompanyOnly()

    let keywords = evt.target.dataset.autocompleteValue
    if (!keywords) {
      keywords = this.selectedKeyword()
    }

    fetch('/jobs/search/counter', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          areas: areas,
          major_cities: majorCities,
          categories: categories,
          fields: fields,
          discernings: discernings,
          new_only: newOnly,
          companyOnly: companyOnly,
          keywords: keywords,
        })
      })
      .then(response => response.json())
      .then(responseData => {
        if (responseData === undefined) { return; }

        const count = responseData.toLocaleString();
        if (this.hasSearchCountTarget) {
          this.searchCountTargets.forEach((el) => {
            el.innerHTML = count
          })
        }
      });
  }

  requestHeaders() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }

    return headers
  }

}