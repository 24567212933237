// Entry point for the build script in your package.json
// import '@hotwired/turbo-rails';
import '@/controllers';

import '@/modules/sentry';

document.addEventListener("keydown", event => {
  const submitenter = event.target.getAttribute('submitenter');

  if (event.isComposing || (event.keyCode === 13 && !submitenter)) {
    return false;
  }
});
