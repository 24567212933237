import { Controller } from '@hotwired/stimulus';
var $ = require('jquery');

var txt = document.getElementsByClassName('txt')[0];
var btn = document.getElementById('accordion_btn');

if (txt !== void 0){
  // 4行以上で「続きを見る」ボタンを表示
  if (txt.scrollHeight > txt.offsetHeight) {
    txt.innerHTML = txt.innerHTML.replace(/(<br>)/g,'<br style="display: none">');
    $('.jobSearch__resultListSingleCont dd .btn').addClass('on');
  }
}

// Connects to data-controller='accordion'
export default class extends Controller {
  static targets = ['btn'];
  static classes = ['flag'];

  toggle(evt) {
    const targetBtn = this.btnTarget.classList;
    if (targetBtn.contains(this.flagClass)) {
      this.closeOff();
    } else {
      this.closeOn();
    }
  }

  closeOn(){
    txt.innerHTML = txt.innerHTML.replace(/(<br style="display: none">)/g,'<br>');
    this.toggleCls();
  }

  closeOff(){
    txt.innerHTML = txt.innerHTML.replace(/(<br>)/g,'<br style="display: none">');
    this.toggleCls();
  }

  toggleCls(){
    $(txt).toggleClass('open');
    $(btn).toggleClass('close');
  }
}
