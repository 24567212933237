import { Controller } from '@hotwired/stimulus';
import iziModal from 'izimodal/js/iziModal';

const $2 = require('jquery');

// Connects to data-controller='multiple-entry'
export default class extends Controller {
  static targets = ['form', 'jobOfferId'];
  static values = { 'limit': Number };

  connect() {
    $2.fn.iziModal2 = iziModal;

    $2('.warningModal').iziModal2({
      overlayColor: 'rgba(116, 118, 123, 0.95)',
      width: 500,
      zindex: 1000,
      bodyOverflow: 'true',
      transitionIn: 'fadeInUp',
      radius: 4,
    });
  }

  submit(evt) {
    if (this.checkEntryLimitOver()) {
      $('.entryModal').iziModal('open');
    } else if (this.checkEntryEmpty()) {
      $2('.warningModal').iziModal2('open');
    } else {
      this.formTarget.submit()
    }
  }

  checkEntryEmpty() {
    let entryCount = 0;
    this.jobOfferIdTargets.forEach((el) => {
      if ( el.checked === true ) {
        entryCount++
      }
    })

    return entryCount == 0
  }

  checkEntryLimitOver() {
    let entryCount = 0;
    this.jobOfferIdTargets.forEach((el) => {
      if ( el.checked === true ) {
        entryCount++
      }
    })

    return this.limitValue < entryCount
  }
}
