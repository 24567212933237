import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='search--checkbox-select-sub-item'
export default class extends Controller {
  static targets = ['checkbox', 'checkboxAll'];

  // 子チェックボックスの状態が変更されたときに呼ばれるメソッド
  selectToggle(event) {
    // 全ての子チェックボックスが選択されているかどうかをチェック
    const allChecked = this.checkboxTargets.every(checkbox => checkbox.checked);
    const checkboxAll = this.checkboxAllTarget;

    // 子チェックボックスの状態で親のチェックボックスを設定
    checkboxAll.checked = allChecked;
  }

  // 親チェックボックスの状態が変更されたときに呼ばれるメソッド
  selectAllToggle(event) {
    const isChecked = event.target.checked;

    this.checkboxTargets.forEach((el, index, array) => {
      el.checked = isChecked;
    })
  }
}
