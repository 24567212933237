import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='entry--file-efo'
export default class extends Controller {
  static targets = ['valid', 'submitBtn', 'disableBtn', 'form', 'attachment'];

  static classes = ['hide', 'valid', 'invalid'];

  connect() {
    this.toggleSubmit();
  }

  submit() {
    this.submitBtnTarget.disabled = true
    const entryButton = document.getElementById('btn-complete-entry')
    const processingButton = document.getElementById('btn-processing')
    entryButton!.style.display = 'none'
    processingButton!.style.display = 'flex'
    this.formTarget.submit()
  }

  validate(evt) {
    const { target } = evt;
    const { dataset } = target;
    const { validId } = dataset;

    const invalid = this.checkValid(target);
    this.checkField(target, invalid, validId);
    this.toggleSubmit();
  }

  checkValid(target) {
    const file = target.files[0];
    if (!file) { return false; }

    let invalid = this.validFileSize(file);

    if (!invalid) {
      invalid = this.validFileType(file);
    }

    if (!invalid) {
      invalid = this.validDuplication(file);
    }

    return invalid;
  }

  checkAllValid() {
    const result = true;

    let containOk = false;
    let containNg = false;

    this.validTargets.forEach((el) => {
      const checkOk = el.classList.contains(this.validClass);
      const checkNg = el.classList.contains(this.invalidClass);

      if (checkOk) { containOk = true; }
      if (checkNg) { containNg = true; }
    });

    return containOk && !containNg;
  }

  toggleSubmit() {
    if (this.checkAllValid()) {
      this.submitBtnTarget.classList.remove(this.hideClass);
      this.disableBtnTarget.classList.add(this.hideClass);
    } else {
      this.submitBtnTarget.classList.add(this.hideClass);
      this.disableBtnTarget.classList.remove(this.hideClass);
    }
  }

  checkField(element, invalid, validId) {
    const error = document.getElementById(`error_${validId}`);

    if (invalid) {
      element.classList.remove(this.validClass);
      element.classList.add(this.invalidClass);

      error.innerHTML = this.errorMessage(invalid);
    } else {
      element.classList.add(this.validClass);
      element.classList.remove(this.invalidClass);

      error.innerHTML = '';
    }
  }

  errorMessage(validType) {
    if (validType === 'file_size') {
      return 'ファイルサイズをご確認ください';
    } if (validType === 'file_type') {
      return 'ファイル形式をご確認ください';
    } if (validType === 'duplication') {
      return '同名のファイルは添付できません';
    }
  }

  validFileSize(file) {
    const size_in_megabytes = file.size / 1024 / 1024;
    const max_size = 5;
    if (size_in_megabytes > max_size) {
      return 'file_size';
    }
  }

  validFileType(file) {
    const valid_extensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'rtf', 'zip', 'lzh', 'jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'bmp'];
    const extension = file.name.split(/\.(?=[^.]+$)/).pop().toLowerCase();
    if (valid_extensions.indexOf(extension) < 0) {
      return 'file_type';
    }
  }

  validDuplication(file) {
    const fileNameArray = [];

    this.attachmentTargets.forEach((el) => {
      const currentFile = el.files[0];

      if (currentFile && /iP(hone|(o|a)d)/.test(navigator.userAgent)) {
        // iosではファイル名で最終更新日付が変わるため
        fileNameArray.push(`${currentFile.type}_${currentFile.size}`);
      } else if (currentFile) {
        fileNameArray.push(currentFile.name);
      }
    });

    const uniqueArray = fileNameArray.filter((x, i, self) => self.indexOf(x) === i);

    if (fileNameArray.length != uniqueArray.length) {
      return 'duplication';
    }
  }

  // 離脱ブロック
}
