import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='search--select-condition'
export default class extends Controller {
  static targets = ['searchCount', 'item'];

  selectToggle(evt) {
    this.updateCounter(evt)
  }

  selectedArea() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (!isModal && el.name === 'areas_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedMajorCity() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (!isModal && el.name === 'major_cities_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedCategory() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (!isModal && el.name === 'categories_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedField() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'fields_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedDiscerning() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'discernings_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedNewOnly() {
    var array = false
    this.itemTargets.forEach((el) => {
      if (el.name === 'new_only' && el.checked === true) {
        array = true
      }
    })

    return array
  }

  selectedCompanyOnly() {
    var array = false
    this.itemTargets.forEach((el) => {
      if (el.name === 'company_name_only' && el.checked === true) {
        array = true
      }
    })

    return array
  }

  selectedKeyword() {
    var keyword = ''
    this.itemTargets.forEach((el) => {
      if (el.name === 'keywords') {
        keyword = el.value.trim()
      }
    })

    return keyword
  }

  selectedAreaValues() {
    return this.selectedArea().map(item => item.value).join(',')
  }

  selectedMajorCityValues() {
    return this.selectedMajorCity().map(item => item.value).join(',')
  }

  selectedCategoryValues() {
    return this.selectedCategory().map(item => item.value).join(',')
  }

  selectedFieldValues() {
    return this.selectedField().map(item => item.value).join(',')
  }

  selectedDiscerningValues() {
    return this.selectedDiscerning().map(item => item.value).join(',')
  }

  updateCounter(evt) {
    const headers = this.requestHeaders()
    
    const areas = this.selectedAreaValues()
    const majorCities = this.selectedMajorCityValues()
    const categories = this.selectedCategoryValues()
    const fields = this.selectedFieldValues()
    const discernings = this.selectedDiscerningValues()
    const newOnly = this.selectedNewOnly()
    const companyOnly = this.selectedCompanyOnly()

    let keywords = evt.target.dataset.autocompleteValue
    if (!keywords) {
      keywords = this.selectedKeyword()
    }

    fetch('/jobs/search/counter', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          areas: areas,
          major_cities: majorCities,
          categories: categories,
          fields: fields,
          discernings: discernings,
          new_only: newOnly,
          companyOnly: companyOnly,
          keywords: keywords,
        })
      })
      .then(response => response.json())
      .then(responseData => {
        if (responseData === undefined) { return; }

        const count = responseData.toLocaleString();
        if (this.hasSearchCountTarget) {
          this.searchCountTargets.forEach((el) => {
            el.innerHTML = count
          })
        }
      });
  }

  requestHeaders() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }

    return headers
  }

}