import { application } from './application';
import { Autocomplete } from 'stimulus-autocomplete'

application.register('autocomplete', Autocomplete)

import FormSubmitController from './form-submit_controller'
application.register('form-submit', FormSubmitController)

import FavoriteController from './favorite_controller'
application.register('favorite', FavoriteController)

import MultipleEntryController from './multiple-entry_controller'
application.register('multiple-entry', MultipleEntryController)

import CheckboxSelectAllController from './checkbox-select-all_controller'
application.register('checkbox-select-all', CheckboxSelectAllController)

import EntryFileSelectController from './entry/file-select_controller'
application.register('entry--file-select', EntryFileSelectController)

import EntryRemoveJobOfferController from './entry/remove-job-offer_controller'
application.register('entry--remove-job-offer', EntryRemoveJobOfferController)

import EntryEfoController from './entry/efo_controller'
application.register('entry--efo', EntryEfoController)

import EntryFileEfoController from './entry/file-efo_controller'
application.register('entry--file-efo', EntryFileEfoController)

import SearchSelectConditionController from './search/select-condition_controller'
application.register('search--select-condition', SearchSelectConditionController)

import SearchSelectModalConditionController from './search/select-modal-condition_controller'
application.register('search--select-modal-condition', SearchSelectModalConditionController)

import SearchClearParamsController from './search/clear-params_controller'
application.register('search--clear-params', SearchClearParamsController)

import SearchResetParamsController from './search/reset-params_controller'
application.register('search--reset-params', SearchResetParamsController)

import SearchCheckboxSelectItemController from './search/checkbox-select-item_controller'
application.register('search--checkbox-select-item', SearchCheckboxSelectItemController)

import SearchCheckboxSelectSubItemController from './search/checkbox-select-sub-item_controller'
application.register('search--checkbox-select-sub-item', SearchCheckboxSelectSubItemController)

import SearchCheckedCheckboxController from './search/checked-checkbox_controller'
application.register('search--checked-checkbox', SearchCheckedCheckboxController)

import AccordionController from './accordion_controller'
application.register('accordion', AccordionController)
