import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='entry--remove-job-offer'
export default class extends Controller {
  static targets = ['remove'];
  static values = {
    'joboffer': String,
    'element': String
  };

  remove(evt) {
    const removeId = this.jobofferValue
    this.updateJobOfferIds(removeId)
    this.removeTarget.remove()
  }

  updateJobOfferIds(removeId) {
    const element = document.getElementById(this.elementValue)
    const beforeJobOfferIds = element.value.split(',')
    const afterJobOfferIds = beforeJobOfferIds.filter(id => id !== removeId)
    element.value = afterJobOfferIds.join(',')
  }
}
