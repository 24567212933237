// app_utils.ts

// 配列をハッシュオブジェクトに変換する関数
export const arrayToHashObject = <T extends Record<string, any>>(
  array: T[],
  key: keyof T
): { [key: string]: T } => {
  return array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {} as { [key: string]: T });
};
