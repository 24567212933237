import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='search--checked-checkbox'
export default class extends Controller {
  static targets = ['item', 'subItem']

  connect() {
    this.itemTargets.forEach((input) => {
      if (input.checked) {
        this.processChecked(input);
      }
    });
  }

  processChecked(input: HTMLInputElement) {
    const itemId = input.dataset.itemId

    this.subItemTargets.forEach((el) => {
      const parentItemId = el.dataset.parentItemId

      if (itemId === parentItemId) {
        el.checked = true;
      }
    })
  }
}
