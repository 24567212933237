import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

var $ = require('jquery');

// Connects to data-controller='favorite'
export default class extends Controller {
  static targets = ['action'];
  static values = { 'jobofferid': Number };
  static classes = ['flag'];

  toggle(evt) {
    const targetFavorite = this.actionTarget.classList;
    if (targetFavorite.contains(this.flagClass)) {
      this.favoriteOff()
    } else {
      this.favoriteOn()
    }
  }

  favoriteOn() {
    const headers = this.requestHeaders()

    fetch('/favorites', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ id: this.jobofferidValue })
      })
      .then(response => {
        const code : Array<number> = [200, 204]
        if (code.indexOf(response.status) !== -1) {
          return response.text()
        } else {
          // Expecting status code 422
          throw new Error('Unprocessable Entity');
        }
      })
      .then(responseData => {
        if (responseData === '') {
          throw new Error('Conflict')
        }
        this.actionTarget.classList.add(this.flagClass)
        const favoriteCount = responseData
        $('.favorite-count').text(favoriteCount);
        const favoriteRemoves = document.getElementsByClassName('favoriteAlert ok');
        for (let favoriteRemove of favoriteRemoves) {
          favoriteRemove.innerHTML = '<span>お気に入りに登録しました</span>'
        }
        $('.favoriteAlert.ok').addClass('on').delay(1000).queue(function(next){
          $(this).removeClass("on");
          next();
        });
      })
      .catch((err) => {
        if (err.message === 'Unprocessable Entity') {
          const favoriteRemoves = document.getElementsByClassName('favoriteAlert ng');
          for (let favoriteRemove of favoriteRemoves) {
            favoriteRemove.innerHTML = '<span>登録上限数に達しています</span>'
          }
          $('.favoriteAlert.ng').addClass('on').delay(1000).queue(function(next){
            $(this).removeClass("on");
            next();
          });
        }
      })
  }

  favoriteOff() {
    const headers = this.requestHeaders()

    fetch('/favorites/' + this.jobofferidValue, {
        method: "DELETE",
        headers: headers
      })
      .then(response => {
        if (response.status == 200) {
          return response.json();
        } else {
           // Expecting status code 422
           throw new Error();
        }
      })
      .then(responseData => {
        this.actionTarget.classList.remove(this.flagClass)
        const favoriteCount = responseData
        $('.favorite-count').text(favoriteCount);
        const favoriteRemoves = document.getElementsByClassName('favoriteAlert ok');
        for (let favoriteRemove of favoriteRemoves) {
          favoriteRemove.innerHTML = '<span>お気に入りから削除しました</span>'
        }
        $('.favoriteAlert.ok').addClass('on').delay(1000).queue(function(next){
          $(this).removeClass("on");
          next();
        });
      })
      .catch((err) => {
        const favoriteRemoves = document.getElementsByClassName('favoriteAlert ng');
        for (let favoriteRemove of favoriteRemoves) {
          favoriteRemove.innerHTML = '<span>削除できませんでした</span>'
        }
        $('.favoriteAlert.ng').addClass('on').delay(1000).queue(function(next){
          $(this).removeClass("on");
          next();
        });
      })
  }

  requestHeaders() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }

    return headers
  }

}