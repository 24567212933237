import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='entry--file-select'
export default class extends Controller {
  static targets = ['attached', 'filename', 'input', 'valid', 'attachment'];
  static classes = ['hide', 'valid', 'invalid'];

  connect() {
    this.attachedTarget.classList.add(this.hideClass)
  }

  select(evt) {
    const file = evt.target.files[0]
    if (!file) { return false }
    if (evt.target.classList.contains(this.invalidClass)) { return false }

    this.selectFilename(file)
    this.attachedTarget.classList.remove(this.hideClass)
    this.inputTarget.classList.add(this.hideClass)

    this.validTarget.classList.add(this.validClass)
  }

  remove(evt) {
    const target = evt.target
    const dataset = target.dataset
    const validId = dataset.validId

    this.attachedTarget.classList.add(this.hideClass)
    this.inputTarget.classList.remove(this.hideClass)

    this.validTarget.classList.remove(this.validClass)
    this.validTarget.classList.remove(this.invalidClass)

    this.attachmentTarget.value = ''

    const error = document.getElementById('error_' + validId)
    error.innerHTML = ''
  }

  selectFilename(file) {
    const filename = file.name
    this.filenameTarget.innerHTML = filename
  }
}
