import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='search--reset-params'
export default class extends Controller {
  static targets = ['searchCount', 'item', 'groupItem'];
  static values = { 'query': String };

  reset(evt) {
    this.uncheckedAll()

    const last_search_params = this.queryValue
    const parse_params = this.parseParams(last_search_params)
    this.settingCategory(parse_params['categories'], parse_params['category_ids'])
    this.settingArea(parse_params['areas'], parse_params['area_ids'])

    this.checkedAll(parse_params)
    this.updateCounter()
  }

  parseParams(last_search_params) {
    const search_params = last_search_params.split('&')
    var paramHash = {}
    for (let param of search_params) {
      var paramArray = param.split('=')
      paramHash[paramArray[0]] = paramArray[1]
    }

    return paramHash
  }

  settingCategory(categories, category_ids) {
    if (!categories) { return; }

    const selecteds = document.getElementsByClassName('selectedSearchCategory')
    const decodeCategory = decodeURIComponent(categories)
    const decodeCategoryId = decodeURIComponent(category_ids)
    const arrayCategory = decodeCategory.split(',')
    const arrayCategoryId = decodeCategoryId.split(',')
    for (let selected of selecteds) {
      selected.innerHTML = ''
      for (let i = 0 ; i < arrayCategory.length ; i ++) {
        const category = arrayCategory[i]
        const categoryId = arrayCategoryId[i]
        selected.innerHTML += this.createItemHtml(categoryId, category, 'categories_box')
      }
    }
  }

  settingArea(areas, area_ids) {
    if (!areas) { return; }

    const selecteds = document.getElementsByClassName('selectedSearchArea')
    const decodeArea = decodeURIComponent(areas)
    const decodeAreaId = decodeURIComponent(area_ids)
    const arrayArea = decodeArea.split(',')
    const arrayAreaId = decodeAreaId.split(',')
    for (let selected of selecteds) {
      selected.innerHTML = ''
      for (let i = 0 ; i < arrayArea.length ; i ++) {
        const area = arrayArea[i]
        const areaId = arrayAreaId[i]
        selected.innerHTML += this.createItemHtml(areaId, area, 'areas_box')
      }
    }
  }

  createItemHtml(id, value, name) {
    return `
      <li><label>
          <input type='checkbox'
                 value='${id}'
                 checked='checked'
                 name='${name}[]'
                 data-action='click->search--select-condition#selectToggle'
                 data-search--clear-params-target='item'
                 data-search--reset-params-target='item'
                 data-search--select-condition-target='item'
                 data-item-id='${id}'
                 data-item-value='${value}'>
          <span>${value}</span>
      </label></li>
    `
  }

  uncheckedAll() {
    this.itemTargets.forEach((el) => {
      if (el.name === 'keywords') {
        el.value = ''
      } else {
        el.checked = false
      }
    })
  }

  checkedAll(parse_params) {
    const decodeArea = decodeURIComponent(parse_params['areas'])
    const arrayArea = decodeArea.split(',')
    const decodeCategory = decodeURIComponent(parse_params['categories'])
    const arrayCategory = decodeCategory.split(',')
    const decodeField = decodeURIComponent(parse_params['fields'])
    const arrayField = decodeField.split(',')
    const decodeDiscerning = decodeURIComponent(parse_params['discernings'])
    const arrayDiscerning = decodeDiscerning.split(',')
    const decodeKeyword = decodeURIComponent(parse_params['keywords'])

    this.itemTargets.forEach((el) => {
      if (el.name === 'categories_box[]' && arrayCategory.includes(el.dataset.itemValue)) {
        el.checked = true

        const itemGroupCode = el.dataset.groupCode
        for (let groupItem of this.groupItemTargets) {
          const groupCode = groupItem.dataset.groupCode
          if (groupCode === itemGroupCode) {
            groupItem.classList.remove('u-visually-hidden')
            break;
          }
        }
      }

      if (el.name === 'areas_box[]' && arrayArea.includes(el.dataset.itemValue)) {
        el.checked = true

        const itemGroupCode = el.dataset.groupCode
        for (let groupItem of this.groupItemTargets) {
          const groupCode = groupItem.dataset.groupCode
          if (groupCode === itemGroupCode) {
            groupItem.classList.remove('u-visually-hidden')
            break;
          }
        }
      }

      if (el.name === 'fields_box[]' && arrayField.includes(el.dataset.itemValue)) {
        el.checked = true
      }

      if (el.name === 'discernings_box[]' && arrayDiscerning.includes(el.dataset.itemValue)) {
        el.checked = true
      }

      if (el.name === 'keywords') {
        el.value = decodeKeyword
      }
    })
  }

  selectedArea() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (!isModal && el.name === 'areas_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedCategory() {
    var array = []
    this.itemTargets.forEach((el) => {
      const isModal = el.dataset.isModal

      if (!isModal && el.name === 'categories_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedField() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'fields_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedDiscerning() {
    var array = []
    this.itemTargets.forEach((el) => {
      if (el.name === 'discernings_box[]' && el.checked === true) {
        const itemId = el.dataset.itemId
        const itemValue = el.dataset.itemValue
        array.push({id: itemId, value: itemValue})
      }
    })

    let set = new Set(array);
    let setToArr = Array.from(set);

    return setToArr
  }

  selectedKeyword() {
    var keyword = ''
    this.itemTargets.forEach((el) => {
      if (el.name === 'keywords') {
        keyword = el.value.trim()
      }
    })

    return keyword
  }

  selectedAreaValues() {
    return this.selectedArea().map(item => item.value).join(',')
  }

  selectedCategoryValues() {
    return this.selectedCategory().map(item => item.value).join(',')
  }

  selectedFieldValues() {
    return this.selectedField().map(item => item.value).join(',')
  }

  selectedDiscerningValues() {
    return this.selectedDiscerning().map(item => item.value).join(',')
  }

  updateCounter() {
    const headers = this.requestHeaders()

    const areas = this.selectedAreaValues()
    const categories = this.selectedCategoryValues()
    const fields = this.selectedFieldValues()
    const discernings = this.selectedDiscerningValues()
    const keywords = this.selectedKeyword()

    fetch('/jobs/search/counter', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          areas: areas,
          categories: categories,
          fields: fields,
          discernings: discernings,
          keywords: keywords,
        })
      })
      .then(response => response.json())
      .then(responseData => {
        if (responseData === undefined) { return; }

        const count = responseData.toLocaleString();
        if (this.hasSearchCountTarget) {
          this.searchCountTargets.forEach((el) => {
            el.innerHTML = count
          })
        }
      });
  }

  requestHeaders() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }

    return headers
  }
}